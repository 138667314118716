html {
  --background-color: #ffffff;
  --background-color-secondary: #ecfdf0;
  --border-top-silver: #c4c4c4;
  --blm: #000000;
  --blm-rgb: 0, 0, 0;
  --blue-checked-label: #5649e4;
  --blue-not-disabled: #b3d7ff;
  --green-dark: #117480;
  --green-light: #2ee280;
  --disabled-btn: #c8c8c8;
  --text-color: #414141;
  --text-color-light: #7b8185;
  --title-color: #2f2f2f;
  --message-green-dark: #137a80;
  --message-green-light: #2dde80;
  --yellow-dark: #ffdc39;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --input-bg: rgba(255, 255, 255, 0.85)
}

// Responsive
$--xxl: 1679px; //max-width: 1679px;
$--xl: 1439px; //max-width: 1439px;
$--lg: 1279px; //max-width: 1279px;
$--md: 1023px; //max-width: 1023px;
$--sm: 767px; //max-width: 767px
$--xs: 480px; //max-width: 479px;

$--xsh: 450px; //max-height: 450px;

$presentName: 'consultationWidget'