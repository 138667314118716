@import 'mixin';
@import 'variable';

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    line-height: 1.5;
}

body {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem; 
}

##{$presentName} {
    font-family: 'Montserrat', sans-serif;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
        padding: 0;
        margin: 0;
    }
    
    .visually-hidden {
        position: absolute;
        margin: -0.1rem;
        padding: 0;
        width: 0.1rem;
        height: 0.1rem;
        border: 0;
        clip: rect(0 0 0 0);
        overflow: hidden;
    }
    
    .main {
        position: fixed;
        right: 8rem;
        bottom: 3rem;
        border-radius: 2rem;
        transition: all 0.5s;
        max-width: 100%;
        max-height: 65rem;
        z-index: 100;
    
        @include --sm {
            right: 3rem;
            bottom: 3rem;
        }
    
        @include --xs {
            width: 100% !important;
            height: 100% !important;
            right: 0;
            bottom: 0;
            border-radius: 0;
            top: 0;
            max-height: 100%;
        }
    
        @include --xsh {
            width: 100% !important;
            height: 100% !important;
            right: 0;
            bottom: 0;
            border-radius: 0;
            top: 0;
        }
    
        &__wrapper {
            background: var(--white);
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            transition: all 0.5s;
            border-radius: 2rem;
            width: 40rem;
            height: 65rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            @include --xs {
                width: 100% !important;
                height: 100% !important;
                border-radius: 0;
                overflow-y: auto;
                max-height: 100%;
            }
    
            @include --xsh {
                width: 100% !important;
                height: 100% !important;
                overflow-y: auto;
                border-radius: 0;
            }
        }
    
        &__full {
            width: 76rem;
            height: 63rem;
            @include --lg {
                width: 64rem;
            }
        }
    
        &__small {
            width: 40rem;
            height: auto;
    
            .main__footer {
                .main__footer-item {
                    border-top: none;
                }
            }
    
            @include --xs {
                height: 100%;
                width: 100%;
            }
    
            @include --xsh {
                height: 100%;
                width: 100%;
            }
        }
    
        &__close {
            transform: translateY(150%);
            opacity: 0;
        }
    
        &__header {
            color: rgb(255, 255, 255);
            background: linear-gradient(93.25deg, var(--green-dark) 8.2%, var(--green-light) 96.29%);
            border-radius: 2rem 2rem 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem 2rem;
            z-index: 9;
            @include --xs {
                border-radius: 0;
                padding: 1rem;
            }
            @include --xsh {
                border-radius: 0;
                padding: 1rem;
            }
    
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
    
                & > span {
                    padding-left: 1.5rem;
                }
            }
    
            &-block {
                display: flex;
                align-items: center;
                margin-right: 1.5rem;
                cursor: pointer;
                &:last-child {
                    margin-right: 0;
                }
            }
            &-resize {
                @include --xs {
                    display: none;
                }
                @include --xsh {
                    display: none;
                }
            }
    
            &-img {
                width: 6.2rem;
                height: 6.2rem;
                vertical-align: middle;
            }
    
            &-lang {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                z-index: 9;
    
                .languages__block {
                    right: 0;
                }
            }
        }
    
        &__body {
            width: 100%;
            height: 100%;
            background: linear-gradient(333deg, var(--background-color-secondary) 6.32%, var(--background-color) 96.65%);
            padding: 2.4rem 2.4rem 4rem 2.4rem;
            position: relative;
            overflow-y: auto;
            transition: all 0.5s;
            flex-grow: 1;
    
            &-item {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-bottom: 3.2rem;
                &:last-child {
                    padding-bottom: 0;
                }
            }
            &-message {
                border-radius: 1.5rem;
                background: linear-gradient(94.94deg, var(--message-green-dark) -36.06%, var(--message-green-light) 95.22%);
                padding: 1.5rem;
                color: var(--white);
                width: auto;
                line-height: 2.1rem;
                box-shadow: 0 2px 4px rgba(210, 210, 210, 0.5);
                word-break: break-word;
                max-width: 85%;
    
                &_file {
                    max-width: 24rem;
                    padding: 0;
                    line-height: 0;
                    position: relative;
    
                    &.main__body-consultant-message {
                        & .link-icon {
                            left: calc(100% + 1.5rem);
                        }
                    }
    
                    & img {
                        width: 100%;
                        height: auto;
                    }
    
                    & .link-icon {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: calc(100% + 1.5rem);
    
                        & > svg {
                            width: 2.2rem;
                            height: 2.2rem;
                            fill: transparent;
                            transition: 0.2s;
                        }
                    }
                }
    
                &-link {
                    color: inherit;
                    display: block;
                    border-radius: 1.5rem;
                    overflow: hidden;
    
                    &:hover {
                        & .link-icon svg {
                            fill: #aeb8c0;
                        }
                    }
                }
            }
            &-time {
                font-size: 1.3rem;
                line-height: 1.8rem;
                color: #aeb8c0;
            }
            &-consultant {
                order: 1;
                &-message {
                    background: var(--white);
                    color: #7b8185;
                }
            }
        }
    
        &__footer {
            @include --xs {
                border-top: 1px solid var(--border-top-silver);
            }
            @include --xsh {
                border-top: 1px solid var(--border-top-silver);
            }
            &-item {
                overflow: hidden;
                padding: 1.5rem 4.8rem 1.5rem 2.4rem;
                border-radius: 0 0 3.2rem 3.2rem;
                @include --xs {
                    padding-left: 1rem;
                    padding-right: 7rem;
                }
    
                &:first-child {
                    border-top: 1px solid rgba(151, 151, 151, 0.3);
                }
                &:last-child {
                    padding: 0 1.6rem 0.8rem 2.4rem;
                    @include --xs {
                        padding-left: 1rem;
                    }
                }
    
                @include --xs {
                    border-radius: 0;
                }
                @include --xsh {
                    border-radius: 0;
                }
                &-buttons {
                    padding: 0 2rem 1rem 2rem;
                    @include --xs {
                        padding: 0 1rem 1rem 1rem;
                    }
                }
            }
            &-input {
                width: 100%;
                outline: none;
                border: none;
                resize: none;
                padding: 0;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.6rem;
                line-height: 1.7rem;
            }
            &-icon {
                width: 2.5rem;
                height: 2.5rem;
                fill: #8b8b8b;
                transition: all 0.5s;
                &:hover {
                    fill: var(--primary);
                }
                &-active {
                    fill: var(--primary);
                }
            }
        }
    
        &__online {
            width: 100%;
            height: auto;
            position: relative;
            color: var(--white);
            z-index: 9;
            margin-top: -2px;
            fill: var(--white);
            @include --xsh {
                height: 15vh;
            }
    
            &-block {
                position: absolute;
                top: 30%;
                transform: translateY(-30%);
                padding: 0 2.4rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include --xs {
                    padding: 0.8rem;
                }
                @include --xsh {
                    padding: 0.8rem;
                }
            }
    
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            &-img {
                cursor: pointer;
                padding-right: 1.6rem;
                vertical-align: middle;
            }
            &-arrow {
                cursor: pointer;
            }
    
            &-login {
                margin-right: 1rem;
            }
        }
    
        &__waiting {
            background: linear-gradient(333deg, var(--background-color-secondary) 6.32%, var(--background-color) 96.65%);
            display: block;
            padding: 4.8rem 3.2rem;
            @include --xs {
                padding: 1.6rem;
                z-index: 9;
                display: flex;
                justify-content: center;
                height: 100%;
                width: 100%;
            }
    
            @include --xsh {
                padding: 1.6rem;
                z-index: 9;
            }
    
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }
    
            &-item:not(:last-child) {
                margin-bottom: 3.2rem;
                @include --xsh {
                    margin-bottom: 2rem;
                }
            }
    
            &-title {
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 2.2rem;
                text-align: center;
                color: var(--blm);
            }
    
            &-text {
                display: block;
                font-weight: bold;
                line-height: 2.1rem;
                color: var(--text-color);
            }
    
            &-center {
                text-align: center;
                flex-direction: column;
                .main__header-lang {
                    .languages__window {
                        left: -32rem;
                        top: -7rem;
                    }
                }
            }
    
            &-review {
                resize: none;
                width: 100%;
                height: 16rem;
                outline: none;
                border-radius: 0.6rem;
                border: 1px solid rgba(148, 148, 148, 0.3);
                padding: 1.6rem;
                font-family: 'Montserrat', sans-serif;
                font-size: 1.4rem;
                line-height: 1.7rem;
                background: var(--input-bg);
            }
    
            &-skip {
                background: linear-gradient(to right, var(--green-dark) 50%, var(--green-light) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: 200% 100%;
                background-position: 100%;
                color: var(--message-green-light);
                font-weight: bold;
                text-align: center;
                transition: background-position 0.5s ease;
                display: block;
                border: none;
                cursor: pointer;
                font-size: 1.4rem;
                line-height: 1.7rem;
                &:hover {
                    color: var(--white);
                    background-position: 0 100%;
                }
            }
    
            &-margin {
                margin-bottom: 3.2rem;
            }
        }
        &__answer {
            display: flex;
            justify-content: space-around;
            max-height: 64rem;
            padding: 2.4rem;
    
            @include --xs {
                max-height: 100%;
                z-index: 9;
                justify-content: center;
            }
    
            @include --xsh {
                max-height: 100%;
                z-index: 9;
            }
        }
        &__emoji {
            overflow: visible;
            display: flex;
        }
        &__write {
            position: absolute;
            bottom: 11rem;
            left: 50%;
            text-align: center;
            color: var(--text-color-light);
            font-size: 12px;
            transform: translateX(-50%);
        }
    }
    
    //BUTTONS STYLE
    .main-circle-button {
        position: absolute;
        right: -3.5rem;
        bottom: 2rem;
        height: 6rem;
        width: 6rem;
        border: none;
        background: linear-gradient(136deg, #31bdb7 4%, #86dc99 100%);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.3s;
    
        img {
            display: block;
        }
    
        &:disabled {
            background: linear-gradient(93deg, var(--disabled-btn) 50%, var(--disabled-btn) 100%);
            &:hover {
                transform: scale(1);
            }
        }
    
        &:hover {
            transform: scale(1.2);
        }
    
        @include --sm {
            right: 0.5rem;
        }
        @include --xs {
            right: 0.5rem;
            bottom: 1.4rem;
        }
        @include --xsh {
            right: 0.5rem;
            bottom: 1rem;
        }
    }
    
    .main-circle-button__open {
        position: fixed;
        right: 4.5rem;
        bottom: 5rem;
        height: 6rem;
        width: 6rem;
        border: none;
        background: linear-gradient(136deg, #31bdb7 4%, #86dc99 100%);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.3s;
    
        img {
            display: block;
        }
    
        &:disabled {
            background: linear-gradient(93deg, var(--disabled-btn) 50%, var(--disabled-btn) 100%);
            &:hover {
                transform: scale(1);
            }
        }
    
        &:hover {
            transform: scale(1.2);
        }
    
        @include --sm {
            right: 0.5rem;
        }
        @include --xs {
            right: 0.5rem;
            bottom: 1.4rem;
        }
        @include --xsh {
            right: 0.5rem;
            bottom: 1rem;
        }
    }
    
    .regulations {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    
        &__inner {
            width: 70vw;
            height: 80vh;
            background-color: var(--white);
            position: relative;
            transition: all 0.5s;
            border-radius: 2.4rem;
    
            @include --sm {
                width: 90vw;
            }
    
            @include --xs {
                height: 90vh;
            }
    
            @include --xsh {
                width: 90vw;
                height: 90vh;
            }
        }
    
        &__wrapper {
            width: 100%;
            height: 100%;
            background-color: var(--white);
            transition: all 0.5s;
            border-radius: 2.4rem;
            overflow-y: auto;
            padding: 5.5rem 3rem 3rem 3rem;
    
            @include --xs {
                padding: 2rem;
            }
            @include --xsh {
                padding: 2rem;
            }
        }
    
        &__title {
            font-weight: bold;
            font-size: 4.8rem;
            line-height: 5.9rem;
            color: var(--title-color);
            position: relative;
            margin-bottom: 9.6rem;
    
            @include --sm {
                font-size: 3.2rem;
                line-height: 4rem;
                margin-bottom: 6rem;
            }
    
            @include --xsh {
                font-size: 3.2rem;
                line-height: 4rem;
                margin-bottom: 6rem;
            }
            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: -4.8rem;
                width: 9rem;
                height: 0.4rem;
                background: var(--yellow-dark);
    
                @include --xs {
                    bottom: -3rem;
                }
                @include --xsh {
                    bottom: -3rem;
                }
            }
        }
    
        &__block {
            & > p {
                font-size: 1.4rem;
                line-height: 2.4rem;
                margin: 1.6rem 0;
                color: var(--text-color);
            }
    
            & > h2,
            h3,
            h4 {
                font-weight: bold;
                color: var(--title-color);
            }
    
            & > h2 {
                font-size: 2.6rem;
                line-height: 3.2rem;
    
                @include --sm {
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                }
    
                @include --xsh {
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                }
            }
            & > h3 {
                font-size: 2.2rem;
                line-height: 3rem;
                @include --sm {
                    font-size: 2rem;
                    line-height: 2.2rem;
                }
    
                @include --xsh {
                    font-size: 2rem;
                    line-height: 2.2rem;
                }
            }
            & > h4 {
                font-size: 1.8rem;
                line-height: 2.8rem;
    
                @include --sm {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
    
                @include --xsh {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
            }
    
            & > ul {
                padding-left: 1.6rem;
                & > li {
                    margin: 1.6rem 0;
                }
            }
        }
    
        &__close {
            position: absolute;
            top: -1.5rem;
            right: -1.5rem;
            width: 3.3rem;
            height: 3.3rem;
            background-color: var(--yellow-dark);
            border-radius: 50%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            & > span {
                background-image: url('/Assets/icons/close.svg');
                background-repeat: no-repeat;
                width: 1.2rem;
                height: 1.2rem;
                transition: all 0.5s;
            }
            &:hover {
                & > span {
                    background-image: url('/Assets/icons/close_hover.svg');
                    transform: rotateZ(270deg);
                }
            }
        }
    }
    
    .main__rating {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    
    .main__star-close {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            cursor: pointer;
            padding: 0.7rem;
            transition: all 0.5s;
            border-radius: 50%;
            &:hover {
                background-color: #86dc99;
            }
        }
    }
    
    .rating {
        flex-direction: column;
        position: relative;
        width: 100%;
        transition: all 0.5s;
        transform: translateY(-78%);
        min-height: 1rem;
        &.active {
            box-shadow: 0 0 0.5rem rgba(0,0,0, 0.1);
            transform: translateY(-5%);
            min-height: auto;
            padding: 1rem;
        }
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__item:not(:last-child) {
            margin-bottom: 0.5rem;
        }
        &__text {
            color: var(--yellow-dark);
            height: 1.7rem;
        }
    }
    
    .languages {
        display: none;
        background-color: var(--white);
        position: absolute;
        top: -6rem;
        right: 2rem;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    
        &__block {
            display: block;
        }
    
        &__wrapper {
            margin: 1rem;
        }
    
        &__item {
            border-bottom: 1px solid #e8e8e8;
            transition: all 0.5s;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                .languages__text {
                    text-shadow: 0 0 0.65px var(--blm), 0 0 0.65px var(--blm);
                }
            }
        }
    
        &__text {
            color: var(--blm);
            margin-right: 2rem;
            min-width: 3.2rem;
            text-align: center;
            text-transform: uppercase;
        }
    
        &__img {
            margin-right: 1.6rem;
        }
    
        &__svg {
            position: absolute;
            right: 1rem;
        }
    
        &__window {
            position: absolute;
            top: -10rem;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: 66rem;
            opacity: 0;
        }
    }
    
    /*==================================================================================FORM==================================================================================*/
    
    .form {
        width: 100%;

        &__item:not(:last-child) {
            padding-bottom: 3.2rem;
        }
    
        &__block:not(:last-child) {
            margin-bottom: 1.6rem;
        }
        &__input {
            outline: none;
            padding: 1rem;
            width: 100%;
            border: 1px solid rgba(148, 148, 148, 0.3);
            font-family: 'Montserrat', sans-serif;
            font-size: 1.4rem;
            line-height: 1.7rem;
            background: var(--input-bg);
        }
    
        &__checkbox {
            position: absolute;
            z-index: -1;
            opacity: 0;
            margin: 0;
            & + label {
                display: inline-flex;
                align-items: center;
                user-select: none;
            }
            & + label::before {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 0.25em;
                margin-right: 0.5em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
                background-color: var(--white);
            }
            &:checked + label::before {
                border-color: var(--blue-checked-label);
                background-color: var(--blue-checked-label);
                background-image: url('/Assets/icons/checkbox_arrow.svg');
            }
    
            /* styles on hover over checkbox */
            &:not(:disabled):not(:checked) + label:hover::before {
                border-color: var(--blue-not-disabled);
            }
            /* styles for the active state of the checkbox (when you click on it) */
            &:not(:disabled):active + label::before {
                background-color: var(--blue-not-disabled);
                border-color: var(--blue-not-disabled);
            }
        }
    
        &__label {
            color: var(--text-color);
            margin-bottom: 1rem;
            display: inline-block;
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__link {
            transition: all 0.2s;
            color: var(--green-dark);
            text-decoration: underline;
            &:hover {
                color: #fed237;
            }
        }
    
        &__btn {
            padding: 1.6rem;
            font-weight: bold;
            text-align: center;
            color: var(--white);
            width: 100%;
            border: none;
            border-radius: 3.2rem;
            background: linear-gradient(93deg, var(--green-dark) 50%, var(--green-light) 100%);
            background-position: 100%;
            background-size: 200% 100%;
            transition: background-position 1s ease;
            cursor: pointer;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    
            &:hover {
                background-position: 0 100%;
            }
            &:disabled {
                background: linear-gradient(93deg, var(--disabled-btn) 50%, var(--disabled-btn) 100%);
            }
        }
    }
    
    /*==================================================================================/FORM==================================================================================*/
    
    /*==================================================================================RATING==================================================================================*/
    
    .rating__stars {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row-reverse;
        & > input {
            display: none;
        }
    
        & > label {
            cursor: pointer;
            width: 3rem;
            height: 3rem;
            background-image: url('./../icons/star_clear.svg');
            background-repeat: no-repeat;
            background-position: center;
            transition: 0.3s;
            margin-right: 0.5rem;
            &:first-child {
                margin-right: 0;
            }
        }
    
        & > input:checked ~ label,
        input:checked ~ label:hover ~ label,
        input:not(:checked) ~ label:hover,
        input:not(:checked) ~ label:hover ~ label,
        input:checked ~ label:active ~ label,
        input:not(:checked) ~ label:active,
        input:not(:checked) ~ label:active ~ label {
            background-image: url('./../icons/star_full.svg');
        }
    }
    
    /*==================================================================================/RATING==================================================================================*/
    
    /*==================================================================================EMOJI-MART==================================================================================*/
    .emoji-mart {
        width: 100% !important;
        &__wrapper {
            position: relative;
            margin-right: 1rem;
            @include --sm {
                display: none;
            }
            @include --xsh {
                display: none;
            }
        }
        &__btn {
            padding: 0;
            border: none;
            background-color: transparent;
        }
        &__picker {
            &-wrapper {
                z-index: 1001;
                position: absolute;
                left: -2rem;
                bottom: 9rem;
                height: 21.5rem;
                overflow: hidden;
                width: 40rem;
                border-bottom: 1px solid #d9d9d9;
                transition: all 0.5s;
                animation-duration: 0.3s;
                animation-fill-mode: both;
                animation-name: slideIn;
            }
        }
        &-category-list {
            & > li > button > span {
                font-size: 19px !important;
            }
        }
    }
    
    .main__footer-icon {
        width: 2.5rem;
        height: 2.5rem;
        fill: #cdd0d4;
        transition: all 0.5s;
        &:hover {
            fill: var(--message-green-dark);
        }
        &-active {
            fill: var(--message-green-dark);
        }
    }
    
    /*==================================================================================/EMOJI-MART==================================================================================*/
    
    .attach-file {
        display: flex;
        justify-content: space-around;
        &__block {
            display: flex;
            margin: 1rem;
            align-items: center;
            position: relative;
            &:first-child {
                margin-left: 2.4rem;
            }
            @include --xs {
                padding-left: 1rem;
            }
        }
        &__btn {
            background-color: transparent;
            border: none;
            cursor: pointer;
            & > svg {
                transition: all 0.5s;
                fill: #cdd0d4;
                &:hover {
                    fill: var(--message-green-dark);
                }
                &-active {
                    fill: var(--message-green-dark);
                }
            }
            &-delete {
                background: transparent;
                border: none;
                cursor: pointer;
                position: absolute;
                top: -0.5rem;
                right: -0.5rem;
                padding: 0;
            }
        }
    
        &__text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 10rem;
            max-width: 10rem;
        }
    
        &__img {
            width: 3rem;
            height: 3rem;
        }
    
        &__close {
            position: absolute;
            top: 0;
            right: -0.5rem;
            width: 1.5rem;
            height: 1.5rem;
            background-color: var(--green-light);
            border-radius: 50%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            font-size: 1rem;
            transition: all 0.3s;
    
            &:hover {
                background-color: var(--green-dark);
            }
        }
    }
    
    div.custom-select {
        &__control {
            border-color: rgba(148, 148, 148, 0.3);
            outline: none;
            box-shadow: none;
            &:hover {
                border-color: rgba(148, 148, 148, 0.3);
            }
        }
    }
    
    /* scroll bar (scrollbar) */
    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
        background-color: transparent;
    }
    
    /* scrollbar slider */
    ::-webkit-scrollbar-thumb {
        background-color: #aeaeae;
        border-radius: 9em;
        box-shadow: inset 1px 1px 10px #f3faf7;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background-color: #8d8d8d;
    }
    
    /* Arrow Scroll */
    
    ::-webkit-scrollbar-button:vertical:start:decrement {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-button:vertical:end:increment {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-button:horizontal:start:decrement {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-button:horizontal:end:increment {
        background-color: transparent;
    }
    
    .dots {
        & span {
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background-color: var(--message-green-light);
            animation: wave 1.3s linear infinite;
    
            &:nth-child(2) {
                animation-delay: -1.1s;
            }
    
            &:nth-child(3) {
                animation-delay: -0.9s;
            }
    
            &:nth-child(4) {
                animation-delay: -0.7s;
            }
        }
        & span:not(:last-child) {
            margin-right: 0.8rem;
        }
    }
}

@keyframes wave {
    0%,
    60%,
    100% {
        transform: initial;
        opacity: 1;
    }

    30% {
        transform: translateY(-1rem);
        opacity: 0.2;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}
